// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	apiUrl: 'https://api.beta.rg.zombiesoup.co/api',
	name: 'Beta',
	stripeKey: 'pk_live_AZNiDXHvNIbOoimt3U9kJNwz00zzMWdgE9',
	hereMapsKey: 'GP-hd6ixjdyiKBXt6-stczIQ94EtABPYmKQ9SU2y7Hk',
};
